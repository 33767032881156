<template>
    <video class="bg-hero" playsinline webkit-playsinline muted poster="/images/poster/entrerHarmony-poster.jpg" rel="preload">
        <source :src='this.$pathprod+"videos/entete/video-entrerHarmony.mp4"' type="video/mp4">
        <source :src='this.$pathprod+"videos/entete/video-entrerHarmony.webm"' type="video/webm">
    </video>
</template>
<script>
    export default {
        name: 'VideoEntrerHarmony',
        props: {
            msg: String,
        },
    }
</script>